// extracted by mini-css-extract-plugin
export var breadcrumbComponent = "sole-order-module--breadcrumbComponent--1FE56";
export var fadeIn = "sole-order-module--fadeIn--1oh6e";
export var order_breadcrumb = "sole-order-module--order_breadcrumb--CigLv";
export var shopify_buy__orders = "sole-order-module--shopify_buy__orders--wsmgS";
export var sole_order_form = "sole-order-module--sole_order_form--IqgeX";
export var sole_order_heading = "sole-order-module--sole_order_heading--ak21P";
export var sole_order_heading2 = "sole-order-module--sole_order_heading2--dY-Wb";
export var sole_order_label = "sole-order-module--sole_order_label--8-Ui6";
export var sole_order_other_desc = "sole-order-module--sole_order_other_desc--ZCepL";
export var sole_order_other_item = "sole-order-module--sole_order_other_item--O6S44";
export var sole_order_other_price = "sole-order-module--sole_order_other_price--e-BAE";
export var sole_order_other_text = "sole-order-module--sole_order_other_text--X0mMQ";
export var sole_order_other_title = "sole-order-module--sole_order_other_title--rDkCD";
export var sole_order_plan_item = "sole-order-module--sole_order_plan_item--f9skg";
export var sole_order_price = "sole-order-module--sole_order_price--EfgYX";
export var sole_order_text = "sole-order-module--sole_order_text--QI4Tq";
export var sole_order_wrapper = "sole-order-module--sole_order_wrapper--eWpeF";
import { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import Logo from '~/components/nav/common_logo_wh.svg'
import Arrow from '~/images/common_icon_arrow_r.svg'
import { Seo } from '~/components'
import * as s from '~/components/pages/sole/sole-order.module.scss'
import Data from '~/json/sole-plans.json'
import { ShopifyBuyInit } from '../../lib/utils'

const ApplyPage = () => {
  const loadingRef = useRef()
  function loadingInit() {
    loadingRef.current.classList.remove('is-visible')
  }
  const [soles, setSoles] = useState([])
  const [whiteBlackSoles, setWhiteBlackSoles] = useState([])
  const [others, setOthers] = useState([])

  useEffect(() => {
    if (
      !window.location.href.includes('order') ||
      typeof window === 'undefined'
    )
      return

    loadingInit()

    Data.plans.forEach(({ buyId, elementId }) =>
      ShopifyBuyInit({
        buyId,
        elementId,
        type: 'product',
        caption: false,
      })
    )

    setSoles(Data.plans.filter(item => item.type === 'sole'))
    setWhiteBlackSoles(
      Data.plans.filter(item => item.type === 'whiteBlackSole')
    )
    setOthers(Data.plans.filter(item => item.type === 'other'))
  }, [])

  const renderPlans = plans => {
    return plans.map(({ elementId, title, price, descriptionForOrder }) => (
      <div key={elementId} className={s.sole_order_plan_item}>
        <h3
          className={`${s.sole_order_heading2} heading is-secondary text-accent`}
        >
          {title}
        </h3>
        <p
          className={s.sole_order_text}
          dangerouslySetInnerHTML={{ __html: descriptionForOrder }}
        />
        <p className={`${s.sole_order_price} shopify-buy__product__price`}>
          {price}
        </p>
        <div className={s.sole_order_form}>
          <div className={s.sole_order_label}>カラーをお選びください</div>
          <div id={elementId} />
        </div>
      </div>
    ))
  }

  const renderOtherPlans = plans => {
    return plans.map(({ elementId, title, price, descriptionForOrder }) => (
      <div key={elementId}>
        <div className={`${s.sole_order_form} sole_order_form`}>
          <div className={s.sole_order_other_item}>
            <p className={s.sole_order_other_title}>{title}</p>
            {descriptionForOrder ? (
              <p className={s.sole_order_other_desc}>{descriptionForOrder}</p>
            ) : null}

            <p
              className={`${s.sole_order_other_price} shopify-buy__product__price`}
            >
              {price}
            </p>
          </div>
          <div id={elementId} />
        </div>
      </div>
    ))
  }

  return (
    <>
      <Seo title="order" pageUrl="order" />
      <div
        ref={loadingRef}
        id="loadingArea"
        className="orderLoading is-visible"
      >
        <div className="loadingContent">
          <div>
            <div role="img" aria-label="Loading">
              <Logo />
            </div>
            <div className="loadingBar" />
          </div>
        </div>
      </div>

      <div id="sole_order_page" className="pageComponent">
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">ORDER</h1>
        </header>
        <nav className="contentComponent">
          <ul className={`${s.breadcrumbComponent}  mincho`}>
            <li>
              <Link to="/sole" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>ご注文プラン選択</li>
          </ul>
        </nav>
        <div className={`${s.sole_order_wrapper} contentComponent`}>
          <h2 className={`${s.sole_order_heading} heading is-primary-l`}>
            SNISH Sole
          </h2>
          {renderPlans(soles)}
        </div>

        <div className={`${s.sole_order_wrapper} contentComponent`}>
          <h2 className={`${s.sole_order_heading} heading is-primary-l`}>
            SNISH White/Black Sole
          </h2>
          {renderPlans(whiteBlackSoles)}
        </div>

        <div
          className={`${s.sole_order_wrapper} contentComponent sole_order_other_wrap`}
        >
          <h2 className={`${s.sole_order_heading} heading is-primary-l`}>
            OTHER
          </h2>
          <p className={s.sole_order_other_text}>
            カカトのみの加工や、加水分解したソールの張り替えなど
          </p>
          {renderOtherPlans(others)}
        </div>
      </div>
    </>
  )
}
export default ApplyPage
